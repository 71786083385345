<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <PageHeading :breadcrumbs="breadcrumbs" title="System Notifications" />

            <Datatable :data="notifications" :columns="tableColumns">
                <template v-slot:status="{ item }">
                    <Badge :type="getBadgeType(item.status)">
                        {{ item.status.replace('_', ' ') }}
                    </Badge>
                </template>
            </Datatable>
        </div>
    </div>
</template>

<script>
import { SystemNotificationsClient } from '@/api/system-notification';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import PageHeading from '@/components/ui/PageHeading';
import Datatable from '@/components/Datatable.vue';
import Badge from '@/components/ui/Badge';
import { format, parseISO } from 'date-fns';
import { SystemNotificationStatus } from '@/enums';

export default {
    name: 'SystemNotifications',
    components: {
        PageHeading,
        Datatable,
        Badge,
    },
    data() {
        return {
            breadcrumbs: [],
            notifications: { items: [] },
            LoadingFlag,
            tableColumns: [
                {
                    value: 'message',
                    header: 'MESSAGE',
                    type: 'text',
                    width: '60%',
                },
                {
                    value: 'type',
                    header: 'TYPE',
                    type: 'text',
                    width: '15%',
                },
                {
                    value: 'status',
                    header: 'STATUS',
                    type: 'slot',
                    width: '10%',
                },
                {
                    value: 'createdAt',
                    header: 'TIMESTAMP',
                    type: 'text',
                    width: '15%',
                },
            ],
        };
    },
    async created() {
        this.setBreadcrumbs();
        const results = await SystemNotificationsClient.getSystemNotifications();

        this.notifications.items = results.map((result) => ({
            message: result.message,
            id: result.entityId,
            type: result.entityType,
            status: result.status,
            createdAt: format(parseISO(result.createdAt), 'yyyy-MM-dd HH:mm:ss'),
        }));
    },
    methods: {
        setBreadcrumbs() {
            this.breadcrumbs = [
                new Breadcrumb('System Notifications', { name: 'system-notifications' }),
            ];
        },
        getBadgeType(status) {
            const badgeTypeMap = {
                [SystemNotificationStatus.Created]: 'neutral',
                [SystemNotificationStatus.InProgress]: 'progress',
                [SystemNotificationStatus.Success]: 'success',
                [SystemNotificationStatus.Error]: 'error',
            };
            return badgeTypeMap[status] || 'neutral';
        },
    },
};
</script>
